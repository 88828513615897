import React, {useState} from 'react';
import * as Survey from "survey-react"
import { questions } from "./questions"
import styled from "styled-components"
import { colors } from "../colors";
import axios from "axios"
import {ImCheckmark} from "react-icons/im";
import {ButtonGf} from "../Button/styles";
import "survey-react/modern.css"


function GFQuestionareRaw({className, topElement}) {
  const [sending , setSending] = useState(false)
  const [showSurvey, setShowSurvey] = useState(true)
  const sendDataToServer = async (survey, options) => {
    setShowSurvey(false)
    setSending(true)
    try {
      //xyybqzqq
      //mknplgzv
      const response = await axios.post("https://formspree.io/f/xbjqgljl",
        { ...survey.data }
      )
      topElement.current.scrollIntoView()
      setSending(false)
    }catch(err) {
      console.log(err)
    }
  }
  return (
    <div className={className}>
      {showSurvey && <Survey.Survey json={questions} onComplete={ sendDataToServer } />}
      { sending ? <h3 className="loader">Sending data...</h3> : !showSurvey ? <div className="success" style={{display: "flex", textAlign:"center", flexDirection:"column", justifyContent: "center", alignItems: "center", width:"100%", height:"100vh"}}>
        <div>
          <div><ImCheckmark size="75px" color={colors.primaryGreen} /></div>
          <span style={{display:"inline-block", marginTop:"30px", fontSize: "2rem", fontWeight:"bold", marginBottom:"30px"}}>Thank you for filling out this questionnaire!</span>
          <br/>
          <p>We are processing your answers and will get back to you shortly.</p>
          <ButtonGf onClick={e => {setShowSurvey(true); setSending(false)}}>Go back</ButtonGf>
        </div>
      </div> : null }
    </div>
  );
}

const GFQuestionare = styled(GFQuestionareRaw)`
  max-width: 800px;
  margin: auto;
  padding: 50px 0;
  background: white;
  color:${colors.primaryGrey} !important;
  .sv-title {
    font-size:16px;
  }
  .sv-question__header--location--top {
    margin-bottom:0;
  }
  .sv-btn {
    border-radius: 3px;
    background-color: ${colors.primaryGreen} !important;
  }

  textarea {
    padding: 20px;
  }
  .sv-root-modern .sv-footer__next-btn {
    float:none;
  }
  .sv-question__title--answer {
    background-color: rgb(140 166 104 / 24%);
  }

  .sv-checkbox--checked {
    .sv-checkbox__svg {
      background-color: ${colors.primaryGreen}
    }
  }

  .sv-comment {
    width: 100%;
    min-height: 300px;
  }
  td, th {
    border:none;
  }
  h3 {
    &.loader {
      padding:0 30px;
      margin-bottom:0;
    }
  }
  .success {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.77);
    z-index:200;
    > div {
      padding: 30px;
      background: white;
    }
  }
`

export default GFQuestionare;
