import React, {useRef} from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Questionare from "../components/Questionare/Questionare"
import Container from "../components/Container"
import {colors} from "../components/colors"
// import "survey-react/modern.css"
import SpecH from "../components/Heading/SpecH"
import loadable from '@loadable/component'
// const { StylesManager } = require("survey-react")

// const Questionare = loadable(() => import("../components/Questionare/Questionare"));


const styles = {
  backgroundColor: colors.thirdGrey,
  padding:"50px 0"
}

const titleStyles = {
  fontSize:"27px",
  textTransform: "uppercase",
  marginBottom:"50px"
}

const Contact = (props) => {
  const topElement = useRef(null)
  return (
    <Layout path={props.location.pathname}>
      <Seo
        title="KW Hybrid Lifeguard | Schedule an appointment or consult an expert"
        description="Fill out the form and we will get back to you shortly."
        keywords="Contact, hybrid car, hybrid battery, schedule an appointment, car repair shop, charge my hybrid
battery, renew my battery, car repair kitchener, KW Hybrid Lifeguard"
      />
      <section style={styles}>
        <Container>
          <div ref={topElement} className="hidden"/>
          <SpecH style={titleStyles}>Contact Us</SpecH>
          <Questionare topElement={topElement} />
          <SpecH style={{marginTop: "40px", marginBottom: "40px"}} topPos="3"><span className="bolder">Best Price</span> Guarantee</SpecH>
          <p style={{textAlign:"center", color:colors.primaryGrey }}>If you find a better price, we will beat it!</p>
          <p style={{textAlign:"center", color:colors.primaryGrey}}>*In written confirmation</p>
        </Container>
      </section>
    </Layout>
  )
}

export default Contact
