import React from "react"
import styled from "styled-components"
import ImpulseImg from "../../images/svg/impuls.svg"
import {colors} from "../colors"

const SpecH = styled(({ className, children, topPos, style }) => {
  return (
    <div style={style ? style : null} className={className}>
      <div className="before">
        <img src={ImpulseImg} alt="" />
      </div>
      <h3>{children}</h3>
      <div className="after">
        <img src={ImpulseImg} alt="" />
      </div>
    </div>
  )
})`
  position:relative;
  padding:0 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  h3 {
    margin:0 1rem;
    font-weight: 300;
    color:${colors.primaryGrey};
    font-size:calc(24px + (40 - 24) * ((100vw - 300px) / (1600 - 300)));
  }
  .after, .before {
    position:relative;
    top:${({topPos}) => topPos ? topPos + "px" : "5px" };
    max-width:75px;
    img {
      display:block;
      width:100%;
    }
  }
  .bolder {
      color: ${colors.primaryGreen};
      text-transform: uppercase;
      font-weight: bold;
  }
  &:after,&:before {
    //position:absolute;
    // content:"";
    // display:block;
    // width:calc(18px );
    // height:auto;
    // background:url(${ImpulseImg}) no-repeat center;
    // background-size:contain;
  }
  //&:after {
  //  right:0;
  //  top:0;
  //  bottom:0;
  //}
  //&:before {
  //  left:0;
  //  top:0;
  //  bottom:0;
  //}
`

export default SpecH
