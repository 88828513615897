export const questions = {
  "pages": [
    {
      "name": "startpage",
      "elements": [
        {
          "type": "radiogroup",
          "name": "question1",
          "titleLocation": "hidden",
          "defaultValue": "I have a hybrid car",
          "isRequired": true,
          "choices": [
            {
              "value": "I have a hybrid car",
              "text": "I have a hybrid car"
            },
            {
              "value": "I don't have a hybrid car",
              "text": "I don't have a hybrid car"
            },
            {
              "value": "I want a hybrid car",
              "text": "I want a hybrid car"
            },
            {
              "value": " want an expert to come to me",
              "text": "I want an expert to come to me"
            }
          ]
        }
      ]
    },
    {
      "name": "have_hybrid",
      "elements": [
        {
          "type": "text",
          "name": "name",
          "title": "Name",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "text",
          "name": "email",
          "title": "Email",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "text",
          "name": "phone",
          "title": "Phone number",
          "hideNumber": true
        },
        {
          "type": "text",
          "name": "message",
          "title": "Message",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "text",
          "name": "car_brand",
          "title": "Car brand",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "text",
          "name": "question2",
          "title": "Year",
          "hideNumber": true,
          "isRequired": true
        }
      ],
      "visibleIf": "{question1} = 'I have a hybrid car'"
    },
    {
      "name": "not_have_hybrid",
      "elements": [
        {
          "type": "text",
          "name": "not_have_hybrid_name",
          "title": "Name",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "text",
          "name": "not_have_hybrid_email",
          "title": "Email",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "text",
          "name": "not_have_hybrid_phone",
          "title": "Phone number",
          "hideNumber": true
        },
        {
          "type": "text",
          "name": "not_have_hybrid_message",
          "title": "Message",
          "hideNumber": true,
          "isRequired": true
        }
      ],
      "visibleIf": "{question1} = 'I don\\'t have a hybrid car' or {question1} = 'I want a hybrid car'"
    },
    {
      "name": "want_hybrid_car_year",
      "elements": [
        {
          "type": "text",
          "name": "want_hybrid_name",
          "title": "Name",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "text",
          "name": "want_hybrid_email",
          "title": "Email",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "text",
          "name": "want_hybrid_phone",
          "title": "Phone number",
          "hideNumber": true
        },
        {
          "type": "text",
          "name": "want_hybrid_address",
          "title": "Address",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "dropdown",
          "name": "want_hybrid_select",
          "title": "Please select the service you are interested in:",
          "hideNumber": true,
          "isRequired": true,
          "choices": [
            {
              "value": "Battery diagnostic",
              "text": "Battery diagnostic"
            },
            {
              "value": "Battery replacement",
              "text": "Battery replacement"
            }
          ]
        },
        {
          "type": "text",
          "name": "want_hybrid_message",
          "title": "Message",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "text",
          "name": "want_hybrid_brand",
          "title": "Car brand",
          "hideNumber": true,
          "isRequired": true
        },
        {
          "type": "text",
          "name": "want_hybrid_year",
          "title": "Year",
          "hideNumber": true,
          "isRequired": true
        }
      ],
      "visibleIf": "{question1} = ' want an expert to come to me'"
    }
  ]
}