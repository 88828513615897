import React from "react"
import styled from "styled-components"
import {colors} from "../colors"
import { Link } from "gatsby"



export const ButtonLink = styled(({children, className, to}) => {
    return <div className={className}>
        <Link to={to}>{children}</Link>
    </div>
})`
  width: ${props => props.width ? props.width : "auto"};
  //color: ${props => props.color ? props.color : colors.primaryGreen};
  border-radius: 3px;
  border: 2px solid ${colors.primaryGreen};
  margin: ${ props => props.margin ? props.margin : "0 10px"};
  padding: 6px 15px;
  background-color: ${props => props.background ? props.background : "transparent"};
  transition: all .3s ease-in;
  outline:none;
  font-weight:bold;
  a {
    color: ${props => props.color ? props.color : colors.primaryGreen};
    text-decoration: none;
  }
  &:hover {
    a {
      color: ${props => props.hoverColor ? props.hoverColor : "white"};
    }
    cursor: pointer;
    background-color: ${props => props.bgHoverColor ? props.bgHoverColor : colors.primaryGreen};
    border-color: ${props => props.bgHoverColor ? props.bgHoverColor : colors.primaryGreen};
  }
`

export const ButtonGf = styled.button`
  color: ${props => props.color ? props.color : colors.primaryGreen};
  border-radius: 3px;
  border: 2px solid ${colors.primaryGreen};
  margin: 0 10px;
  padding: 6px 15px;
  background-color: ${props => props.background ? props.background : "transparent"};
  transition: all .3s ease-in;
  outline:none;
  font-weight:bold;
  &:hover {
    cursor: pointer;
    color: ${props => props.hoverColor ? props.hoverColor : "white"};
    background-color: ${props => props.bgHoverColor ? props.bgHoverColor : colors.primaryGreen};
    border-color: ${props => props.bgHoverColor ? props.bgHoverColor : colors.primaryGreen};
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position:relative;
  z-index:90;
  @media screen and (min-width: ${props => props.screen ? props.screen : "768px"}) {
    justify-content: ${props => props.align ? props.align : "flex-start"}
  }
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  button {
    margin-bottom:20px;
  }
  button:first-child {
    margin-left:0;
  }
`